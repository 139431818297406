var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-container",
        [
          _c("CommonTree", {
            staticStyle: { "flex-shrink": "0" },
            attrs: {
              treeData: _vm.treeData,
              defaultProps: _vm.defaultProps,
              searchTitle: _vm.searchTitle,
              isShowdig: false,
              showCheckbox: false,
              "popover-show": false,
              expandOnClickNode: false,
              treeTitle: "培训类型",
            },
            on: {
              getTreeAdd: _vm.treeMenuAdd,
              getTreeEdit: _vm.treeMenuEdit,
              getTreeDelete: _vm.treeNodeDel,
              getNodeClick: _vm.treeNodeClick,
            },
          }),
          _c(
            "el-tabs",
            {
              staticStyle: { width: "calc(100% - 280px)", height: "100%" },
              attrs: { type: "border-card" },
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "平台课程", name: "platformCourses" } },
                [
                  _c("platform-courses", {
                    ref: "platformCourses",
                    attrs: { initTree: _vm.initTree },
                    on: { courseSubmit: _vm.courseSubmit },
                  }),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "自定义课程", name: "customCourses" } },
                [
                  _c("custom-courses", {
                    ref: "customCourses",
                    attrs: { initTree: _vm.initTree },
                    on: { courseSubmit: _vm.courseSubmit },
                  }),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "我的收藏", name: "myCollection" } },
                [
                  _c("myCollection", {
                    ref: "myCollection",
                    attrs: { initTree: _vm.initTree },
                    on: { courseSubmit: _vm.courseSubmit },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }