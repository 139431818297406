<template>
  <div>
    <grid-head-layout
      ref="searchForm"
      :search-columns="searchColumns"
      @grid-head-search="searchChange"
      @grid-head-empty="searchReset"/>
    <grid-layout
      class="platformCourses"
      ref="gridLayout"
      :tableOptions="tableOptions"
      :tableData="tableData"
      :tableLoading="tableLoading"
      :data-total="page.total"
      :page="page"
      @page-size-change="onLoad"
      @page-current-change="onLoad"
      @page-refresh-change="onLoad"
      @row-dblclick="dblclick"
      :searchColumns="searchColumns"
    >
      <template #courseCover="{ row }">
      <img class="courseCoverImg" v-if="row.courseCover == ''" src="@/assets/training/trainingDefaultPicture.png">
      <img class="courseCoverImg" v-else :src="row.courseCover">
    </template>
      <template #customBtn="{ row, index }">
        <el-button
          size="small"
          type="text"
          @click.stop="submit(row, index)"
        >
          确定
        </el-button>
      </template>
    </grid-layout>
  </div>
</template>

<script>
import {myCollection, saveTopRecord, cancelTop} from "@/api/training/trainGroupScene";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
import WfFlow from '@/views/plugin/workflow/process/components/flow.vue';
import {mapGetters} from "vuex";
import {dateFormat} from "@/util/date";
import trainingRecordEjectLayer from "@/views/business/training/onSiteTraining/trainingRecordEjectLayer";

export default {
  components: {
    HeadLayout,
    GridLayout,
    WfFlow,
    trainingRecordEjectLayer
  },
  props: {
    initTree: {
      type: Function,
      default: null
    }
  },
  data() {
    return {
      trainingInformation:{},
      trainingRecordEjectLayer:false,
      query: {
        trainContentCode: "",
      },
      total: "",
      page: {
        pageSize: PAGE_CONSTANT.TWENTY,
        currentPage: 1,
        total: 0
      },
      tableLoading: false,
      tableData: [],
      headBtnOptions: [],
      searchColumns: [
        {
          label: "",
          prop: "courseName",
          span: 4,
          placeholder: "请输入课程名称",
        },
        {
          prop: 'whenIsCreatedDate',
          type: 'datetimerange',
          span: 5,
          startPlaceholder: "创建开始时间",
          endPlaceholder: "创建结束时间",
          format: 'yyyy-MM-dd HH:mm:ss',
          valueFormat: 'yyyy-MM-dd HH:mm:ss',
        },
      ],

      tableOptions: {
        indexLabel: "序号",
        index: true,
        linklabel: "courseName",
        menuWidth: 160,
        column: [
          {
            label: "课程名称",
            prop: "courseName",
            align: "left",
            // width: 280,
            overHidden: true,
          },
          {
            label: "课程",
            prop: 'courseCover',
            slot: true,
            width: 120
          },
          {
            label: "时长",
            prop: "duration",
            align: "center",
            width: 120,
            overHidden: true,
          },
          {
            label: "标签",
            prop: "ticket",
            align: "center",
            width: 120,
            overHidden: true,
          },
          {
            label: "创建人",
            prop: "userName",
            align: "center",
            width: 120,
            overHidden: true,
          },
          {
            label: "创建单位",
            prop: "deptName",
            align: "center",
            width: 120,
            overHidden: true,
          },
          {
            label: '创建时间',
            prop: 'createTime',
            width: 150,
            overHidden: true
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["permission", "language", "userInfo"]),

  },
  methods: {
    dblclick(row) {
      this.$emit("courseSubmit", row.row)
    },
    submit(row){
      this.$emit("courseSubmit",row)
    },
    searchReset(params) {
      this.$refs.searchForm.searchForm = {};
      // this.initTree()
      this.query = {}
      this.onLoad(this.page, params)
    },
    searchChange(params) {
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params)
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      let trainContentCode = this.$parent.$parent.$parent.$parent.trainingCode;
      params.trainContentCode = trainContentCode
      params.courseType = this.courseType
      if (params.whenIsCreatedDate) {
        params.startTime = params.whenIsCreatedDate[0]
        params.endTime = params.whenIsCreatedDate[1]
      }
      myCollection(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query)
      ).then(res => {
        const data = res.data.data;
        page.total = data.total;
        this.tableData = data.records;
        this.$nextTick(() => {
          this.$refs.gridLayout.page.total = data.total;
        })
        this.tableLoading = false;
      });
    },
  },
  mounted() {
  },
};
</script>
<style lang="scss" scoped>
.headers {
  background: #ffffff;
  padding: 14px 12px;
}

::v-deep .platformCourses .avue-crud .el-table {
  height: calc(100vh - 316px) !important;
  max-height: calc(100vh - 316px) !important;
}

.headers ::v-deep .el-button {
  height: 28px;
}

.el-button + .el-button {
  margin-left: 5px !important;
}

::v-deep .bjs-powered-by {
  display: none;
}
.courseCoverImg {
  width: 80px;
  padding: 5px;
}
</style>
