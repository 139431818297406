var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("grid-layout", {
        ref: "gridLayout",
        staticClass: "platformCourses",
        attrs: {
          tableOptions: _vm.tableOptions,
          tableData: _vm.tableData,
          tableLoading: _vm.tableLoading,
          "data-total": _vm.page.total,
          page: _vm.page,
        },
        on: {
          "page-size-change": _vm.onLoad,
          "page-current-change": _vm.onLoad,
          "page-refresh-change": _vm.onLoad,
        },
        scopedSlots: _vm._u([
          {
            key: "customBtn",
            fn: function ({ row, index }) {
              return [
                row.status != 1 &&
                row.status != 3 &&
                _vm.isDateBeforeCurrent(row.endTime)
                  ? _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "text" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.continuingTraining(row, index)
                          },
                        },
                      },
                      [_vm._v("\n          继续培训\n        ")]
                    )
                  : _vm._e(),
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "text" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.viewResultRecord(row, index)
                      },
                    },
                  },
                  [_vm._v("\n          查看结果记录\n        ")]
                ),
              ]
            },
          },
        ]),
      }),
      _vm.trainingEducationStatisticsIndexDrawer
        ? _c(
            "el-drawer",
            {
              attrs: {
                title: "培训记录",
                visible: _vm.trainingEducationStatisticsIndexDrawer,
                direction: "rtl",
                "append-to-body": "",
                size: "70%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.trainingEducationStatisticsIndexDrawer = $event
                },
              },
            },
            [
              _c("trainingEducationStatisticsIndex", {
                ref: "trainingEducationStatisticsIndex",
                attrs: {
                  rowData: _vm.rowData,
                  type: "trainingEducationStatistics",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }