<template>
  <div class="main-content">
    <el-container>
      <div style="position: relative" class="content">
        <CommonTreeNew ref="commonTree" treeTitle="组织架构" :defaultProps="Props" :showCheckbox="false"
                       @getNodeClick="treeNodeClick" :urlParmas="{
          tenantId: '',
          deptCategory: '5,2',
          parentId: userInfo.dept_id,
        }" node-key="id" :isShowdig="false" :isZoom="true" :show="show" @showChange="showChange"
                      >
        </CommonTreeNew>
      </div>

      <el-container>
        <el-main>
          <grid-head-layout ref="gridHeadLayout" :grid-head-btn="gridHeadBtn" :search-columns="leftSearchColumns"
                            :deviceProtocol="deviceProtocol" @grid-head-search="leftGridHeadSearch"
                            @grid-head-empty="leftGridHeadEmpty"></grid-head-layout>
          <grid-layout class="departTable" ref="leftGridLayOut" :grid-row-btn="gridRowBtn" :table-options="tableOptions"
                       :table-data="leftTableData" :table-loading="leftTableLoading" :data-total="leftPage.total"
                       :page="leftPage"
                       @page-current-change="leftOnLoad" @page-size-change="leftOnLoad"
                       @page-refresh-change="leftOnLoad">

          </grid-layout>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>

import {getDeptLazyTree, userDeptList} from "@/api/system/dept";
import {
  pageDeptByRole,
  roleAllocation,
  addDeptByRole,
  removeDeptByRole,
} from "@/api/system/user";
import {getDeptTree, userDepartmentSeparationList} from "@/api/system/dept";

let baseUrl = '/api/sinoma-system/dict/dictionary?code='
import {mapGetters} from "vuex";
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import TreeLayout from "@/views/components/layout/tree-layout";

export default {

  name: "protocol",
  components: {
    GridLayout,
    HeadLayout,
    TreeLayout
  },
  props: {
    currentRole: {},
    disabledList: [],
  },
  data() {
    return {
      Props: {
        children: "children",
        label: "title",
      },
      show: true,
      searchKey: '',
      oldData: {},
      leftTableLoading: true,
      rightTableLoading: true,
      leftTableData: [],
      rightTableData: [],
      deviceProtocol: this.verdict,
      leftQuery: {},
      rightQuery: {},
      query: {},
      getDeptTreeDicData: [],
      leftPage: {
        pageSize: PAGE_CONSTANT.TEN,
        currentPage: 1,
        total: 0
      },
      rightPage: {
        pageSize: PAGE_CONSTANT.TEN,
        currentPage: 1,
        total: 0
      },
      componylist: [],
      defaultProps: {
        children: "children",
        label: "title",
        value: "id",
      },

    };
  },
  computed: {
    ...mapGetters(["permission", "userInfo"]),
    leftSearchColumns() {
      return [
        {
          placeholder: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.sys.user.field.account"),
          prop: "account",
          cell: true,
          align: 'left',
          width: 150,
          overHidden: true,
        },
        {
          placeholder: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.sys.user.field.realName"),
          prop: "realName",
          cell: true,
          align: 'left',
          width: 150,
          overHidden: true,
        },
      ]
    },
    tableOptions() {
      return {
        height: 0,
        customAddBtn: true,
        menu: false,
        selection: true,
        reserveSelection: true,
        selectable: this.selectable,
        column: [
          {
            label: this.$t("cip.plat.sys.user.field.account"),
            prop: "account",
            cell: true,
            align: 'left',
            width: 150,
            overHidden: true,
          },
          {
            label: this.$t("cip.plat.sys.user.field.realName"),
            prop: "realName",
            cell: true,
            width: 150,
            align: 'center',
            overHidden: true,
          },
          {
            label: this.$t("cip.plat.sys.user.field.account"),
            prop: "account",
            cell: true,
            align: 'left',
            width: 150,
            overHidden: true,
          },
          {
            label: "年龄",
            prop: "age",
            cell: true,
            align: 'left',
            width: 150,
            overHidden: true,
          },
          {
            label: this.$t("cip.plat.sys.user.field.sex"),
            prop: "sex",
            span: 8,
            type: "select",
            dataType: 'number',
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=gender",
            overHidden: true,
          },
          {
            label: this.$t("cip.plat.sys.user.field.deptName"),
            prop: "deptName",
            cell: true,
            align: 'left',
            overHidden: true,
          },
        ],
      }
    },
    gridRowBtn() {
      return []
    }
  },
  gridHeadBtn() {
    return []
  },
  headBtnOptions() {
  },
  mounted() {

    this.getcomponylist()
  },
  methods: {
    handleSearch() {
      this.searchKey = this.searchKey.trim();
      this.$nextTick(() => {
        if (this.searchKey) {
          this.componylist = this.filterTreeData(this.oldData, this.searchKey);
        } else {
          this.componylist = this.oldData;
        }
      });
    },
    filterTreeData(list, searchKey) {
      return list.reduce((acc, item) => {
        let children = item.children ? this.filterTreeData(item.children, searchKey) : [];
        if (item.title.includes(searchKey) || (children.length > 0)) {
          acc.push({
            ...item,
            children
          });
        }
        return acc;
      }, []);
    },
    selectable(row) {
      return this.disabledList.indexOf(row.userId) === -1
    },
    getcomponylist() {
      getDeptTree('', '2,5', this.userInfo.dept_id).then((res) => {
        let treeData = res.data.data;
        if (treeData.length > 0) {
          this.componylist = treeData;
          this.treeNodeClick(treeData[0])
          this.oldData = treeData;
        }
      })
    },

    confirmTheSubmission() {
      return this.$refs.leftGridLayOut.selectionList
    },
    includeDown(e) {
      this.leftPage.currentPage = 1;
      this.query.isInclude = e;
      this.leftOnLoad(this.leftPage);
    },
    showChange(v) {
      this.show = v;
    },
    /**
     * 部门树结构点击事件
     * @param node
     */
    treeNodeClick(node) {
      this.treeDeptId = node.id;
      this.leftPage.currentPage = 1;
      this.leftOnLoad(this.leftPage);
    },
    leftGridHeadSearch(query) {
      this.leftPage.currentPage = 1;
      this.query = query
      this.leftOnLoad(this.leftPage, query);
    },
    leftGridHeadEmpty(query) {
      this.leftPage.currentPage = 1;
      this.query = query
      this.leftOnLoad(this.leftPage, query);
    },
    leftOnLoad(page, params = {}) {
      this.param = this.query;
      this.leftQuery = this.query
      this.leftPage = page;
      this.leftTableLoading = true;
      this.leftQuery.deptId = this.treeDeptId
      userDeptList(page.currentPage, page.pageSize, Object.assign(params, this.leftQuery)).then(res => {
        const data = res.data.data;
        this.$refs.leftGridLayOut.$refs.grid.page.total = data.total;
        this.leftPage.total = data.total;
        this.leftTableData = data.records;
        this.leftTableLoading = false;
      });
    },
    findChildData(tree, childId) {
      for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.id === childId) {
          // 如果当前节点就是目标子节点，直接返回其数据
          return node;
        } else if (node.children) {
          // 否则递归查找当前节点的子节点
          const childData = this.findChildData(node.children, childId);
          if (childData) {
            // 如果找到了目标子节点的数据，返回该数据
            return childData;
          }
        }
      }
      // 没有找到目标子节点的数据，返回 null
      return null;
    },
  }
};
</script>
<style scoped lang="scss">
.main-content ::v-deep .common_tree_handle .el-tree {
  /* height: 100% !important; */
  height: calc(100vh - 434px) !important;
  overflow-y: scroll;
}
.main-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.btn {
  display: flex;
  align-items: center;
}

.shutleBack {
  width: 33%;
  background: #ffffff;
  border: 1px solid #ebeef5;
  border-radius: 4px;
}

.shutleTitle {
  width: 100%;
  background-color: #f5f7fa;
  height: 40px;
  line-height: 40px;
  text-indent: 12px;
  border-bottom: 1px solid #ebeef5;
  font-size: 16px;
}

.shutContent {
  height: 500px;
  overflow: auto;
}

::v-deep .departTable .avue-crud .el-table {
  height: calc(100vh - 441px) !important;
  max-height: calc(100vh - 441px) !important;
}
</style>
